import React from "react";
import {Permission} from "../../store/types/Permission";
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import Loader from "../../components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {generateTabs} from "../../utils/generateTabs";
import {useRawPermissions} from "../../hooks/usePermissions";
import DetailsWrapper from "../../components/DetailsWraper/DetailsWrapper";
import SupervisorHeader from "../../components/DetailsHeaders/SupervisorHeader";
import RingGroupsTab from "../../components/Tabs/RingGroupsTab";
import AgentsTab from "../../components/Tabs/Agents/AgentsTab";
import usePageTitle from "../../hooks/usePageTitle";
import {actions} from "../../store";
import {useDispatch} from "react-redux";
import {RecentCalls} from "../CallHistory/RecentCalls/RecentCalls";
import {AgentActiveCalls} from "../CallHistory/ActiveCalls/AgentActiveCalls";
import {CallStatistics} from "../CallHistory/CallStatistics/CallStatistics";
import {useStyles} from "./utils";


const SupervisorPanel = () => {

    const isLoading = false
    const classes = useStyles()
    const {t} = useTranslation()
    const permissions = useRawPermissions()
    const dispatch = useDispatch();

    usePageTitle()

    const getBasicData = () => dispatch(actions.getCallHistoryBasicData.request());


    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('screens:ringGroups.ringGroups'),
                permission:
                Permission.SupervisorPanel.RingGroups.value,
                tab: <RingGroupsTab callback={getBasicData}/>,
            },
            {
                title: t('screens:supervisorPanel.agents'),
                permission:
                Permission.SupervisorPanel.Agents.value,
                tab: <AgentsTab callback={getBasicData}/>,
            },
            {
                title: t('screens:calls.currentCalls'),
                permission:
                Permission.SupervisorPanel.CurrentCalls.value,
                tab: <AgentActiveCalls callback={getBasicData}/>,
            },
            {
                title: t('screens:calls.recentCalls'),
                permission:
                Permission.SupervisorPanel.RecentCalls.value,
                tab: (
                    <RecentCalls callback={getBasicData}/>
                ),
            },
            {
                title: t('screens:calls.callStatistics'),
                permission:
                Permission.SupervisorPanel.CallStatistics.value,
                tab: (
                    <CallStatistics callback={getBasicData}/>
                ),
            }
        ],
        permissions,
    );

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="supervisor-loader"/>
            </div>
        );
    }

    return (
        <PermissionPlaceholder
            permission={Permission.SupervisorPanel.value}
            allowedForUserGroups={Permission.SupervisorPanel.segmentedUserAccountTypes}
        >
            <div className={classes.mainContainer}>
                <DetailsWrapper
                    tabs={tabs}
                    tabsNames={tabNames}
                    top={<SupervisorHeader data-testid={'header-component'}/>}
                    defaultTabIndex={0}
                    perm={perm}
                    headerCustomClass={classes.customHeader}
                    doNotControlForms
                />
            </div>

        </PermissionPlaceholder>
    )
}

export default SupervisorPanel