export enum Account {
    GetAccountList = '/Account/get_account_list',
    UpdateAccount = '/Account/update_account',
    GetAccountInfo = '/Account/get_account_info',
    UpdateServiceFeatures = '/Account/update_service_features',
    GetXdrList = '/Account/get_xdr_list',
    GetServiceFeatures = '/Account/get_service_features',
    GetMohListInfo = '/Account/get_moh_list_info',
    GetAccountFollowMe = '/Account/get_account_followme',
    GetPermittedSipProxiesInfo = '/Account/get_permitted_sip_proxies_info',
    AddCpRule = 'Account/add_call_processing_rule',
    DeleteCpRule = 'Account/delete_call_processing_rule',
    UpdateCpRule = '/Account/update_call_processing_rule',
    DeleteCpCondition = '/Account/delete_call_processing_condition',
    UpdateCpCondition = '/Account/update_call_processing_condition',
    AddCpCondition = '/Account/add_call_processing_condition',
    GetCallBarringOptions = '/Account/get_call_barring_options',
    GetCpRuleList = '/Account/get_call_processing_rule_list',
    GetCpConditionList = '/Account/get_call_processing_condition_list',
    UpdateCallBarringOptions = '/Account/update_call_barring_options',
    UpdateAccountsCallProcessingMode = '/Account/update_accounts_call_processing_mode',
    DeleteFollowMeNumber = '/Account/delete_followme_number',
    UpdateFollowMeNumber = '/Account/update_followme_number',
    AddFollowMeNumber = '/Account/add_followme_number',
    ArrangeFollowMeNumbers = '/Account/arrange_followme_numbers',
    UpdateAccountFollowMe = '/Account/update_account_followme',
    UpdateFollowMeNumberList = '/Account/update_followme_number_list',
    AddAccount = '/Account/add_account',
    GetAssignedCpePortsList = '/Account/get_assigned_cpe_ports_list',
    ReleaseUA = '/Account/release_ua',
    AssignUA = '/Account/assign_ua',
    TransactionsTotalInfo = 'Account/get_transactions_total_info'
}

export enum Async {
    GetResponseRequest =  '/AsyncRequest/get_response_of_async_request'
}

export enum CallControl
{
    TransferCall ="CallControl/transfer_call",
    HoldCall = "CallControl/hold_call",
    UnHoldCall = "CallControl/unhold_call",
    TerminateCall="CallControl/terminate_call",
    JoinToActiveCall = "CallControl/join_to_active_call"
}

export enum Customer {
    UpdateCallQueue = '/Customer/update_callqueue',
    GetCustomerInfo = '/Customer/get_customer_info',
    GetCustomerSubdivisionsList = '/Customer/get_customer_list',
    AddCustomerExtension = '/Customer/add_customer_extension',
    GetCustomerExtensionInfo = '/Customer/get_customer_extension_info',
    GetCallBarringOptions = '/Customer/get_call_barring_options',
    UpdateCallBarringOptions = '/Customer/update_call_barring_options',
    GetExtensionsList = '/Customer/get_extensions_list',
    UpdateCustomerExtension = '/Customer/update_customer_extension',
    GetCallQueueList = '/Customer/get_callqueue_list',
    GetCustomerXDRS = '/Customer/get_customer_xdrs',
    ChangePassword = '/Customer/change_password',
    GetCustomerAgreementInfo = '/Customer/get_customer_agreement_info',
    GetHuntGroupList = '/Customer/get_huntgroup_list',
    DeleteCustomerHuntGroup = '/Customer/delete_customer_huntgroup',
    AddCustomerHuntGroup = '/Customer/add_customer_huntgroup',
    UpdateCustomerHuntGroup = '/Customer/update_customer_huntgroup',
    DeleteCallQueue = '/Customer/delete_callqueue',
    GetHuntGroupInfo = '/Customer/get_huntgroup_info',
    AddCallQueue = '/Customer/add_callqueue',
    GetCustomerMohListInfo = '/Customer/get_moh_list_info',
    UpdateCustomerServiceFeature = '/Customer/update_service_features',
    UpdateCustomer = '/Customer/update_customer',
    UpdateServiceFeatures = '/Customer/update_service_features',
    DeleteCustomerExtension = '/Customer/delete_customer_extension',
    GetCustomFieldsSchema = '/Customer/get_custom_fields_schema',
    UpdateCustomFieldsValues = '/Customer/update_custom_fields_values',
    GetCustomerIndividualList = '/Customer/get_customer_individual_list',
    GetCustomerIndividualAllowedRoleList = '/Customer/get_customer_individual_allowed_role_list',
    GetCustomerIndividualInfo = '/Customer/get_customer_individual_info',
    UpdateCustomerIndividual = '/Customer/update_customer_individual',
    AddAbbreviatedDialingNumber = '/Customer/add_abbreviated_dialing_number',
    UpdateAbbreviatedDialingNumber = '/Customer/update_abbreviated_dialing_number',
    DeleteAbbreviatedDialingNumber = '/Customer/delete_abbreviated_dialing_number',
    AddCustomerIndividual = '/Customer/add_customer_individual',
    DeleteCustomerIndividual = '/Customer/delete_customer_individual',
    GetAbbreviatedDialingNumberList = 'Customer/get_abbreviated_dialing_number_list',
    GetCallProcessingOperationModeList = '/Customer/get_call_processing_operation_mode_list',
    GetCallProcessingPolicyInfo = '/Customer/get_call_processing_policy_info',
    GetCallProcessingPolicyList = '/Customer/get_call_processing_policy_list',
    GetTransactionsTotalInfo = '/Customer/get_transactions_total_info',
    GetPaymentMethodInfo = '/Customer/get_payment_method_info',
    GetAutoPaymentInfo = '/Customer/get_auto_payment_info',
    AddAutoPayment = '/Customer/add_auto_payment',
    DeleteAutoPayment = '/Customer/delete_auto_payment',
    UpdateAutoPayment = '/Customer/update_auto_payment',
    DeletePaymentMethod = '/Customer/delete_payment_method',
    UpdatePaymentMethod = '/Customer/update_payment_method',
    EstimateTaxes = '/Customer/estimate_taxes',
    GetCpConditionList = '/Customer/get_call_processing_condition_list',
    AddCallProcessingPolicy = '/Customer/add_call_processing_policy',
    DeleteCallProcessingPolicy = '/Customer/delete_call_processing_policy',
    EditCallProcessingPolicy = '/Customer/update_call_processing_policy',
    AddCallProcessingMode = '/Customer/add_call_processing_operation_mode',
    EditCallProcessingMode = '/Customer/update_call_processing_operation_mode',
    DeleteCallProcessingMode = '/Customer/delete_call_processing_operation_mode',
    AddCpCondition = '/Customer/add_call_processing_condition',
    EditCpCondition = '/Customer/update_call_processing_condition',
    DeleteCpCondition = '/Customer/delete_call_processing_condition',
    GetAssignedCpePortsList = '/Customer/get_assigned_cpe_ports_list',
    UploadExtensions = '/Customer/upload_extensions',
    GetCallQueueInfo = '/Customer/get_callqueue_info',
    GetCallParkingSlots = '/Customer/get_parking_slots_list',
    DeleteCallParkingSlot = '/Customer/delete_parking_slot',
    UpdateCallParkingSlot = '/Customer/update_parking_slot',
    AddCallParkingSlot = '/Customer/add_parking_slot'
}

export enum Generic {
    GetGlobalCurrencyInfo = '/Generic/get_global_currency_info',
    GetConfigData = '/Generic/get_config_data',
    GetSessionData = '/Generic/get_session_data',
    GetSubdivisionsList = '/Generic/get_subdivisions_list',
    GetTimeZoneList = '/Generic/get_time_zone_list',
    GetCountriesList = '/Generic/get_countries_list',
    GetLocaleLanguagesList = 'Generic/get_locale_languages_list',
    GetApiErrorFile = '/Generic/get_api_error_file',
    GetUIConfigList = '/Generic/get_ui_config_list',
    UpdateUIConfigList = '/Generic/update_ui_config_list',
}

export enum DID {
    AssignDIDToAccount = '/DID/assign_did_to_account',
    GetCustomerNumbers = '/DID/get_customer_numbers',
    CancelDIDAssignment = '/DID/cancel_did_assignment',
    DeleteNumber = '/DID/delete_number',
}

export enum DIDProvider {
    GetProviderInfo = '/DID_Provider/get_provider_info',
    GetCountriesList = '/DID_Provider/get_countries_list',
    GetAreasList = '/DID_Provider/get_areas_list',
    GetNumbersList = '/DID_Provider/get_numbers_list',
    Provision_number = '/DID_Provider/provision_number',
}

export enum Voicemail {
    GetVmSettings = '/Voicemail/get_vm_settings',
    GetVmGreeting = '/Voicemail/get_vm_greeting',
    SetVmSettings = '/Voicemail/set_vm_settings',
}

export enum AutoAttendant {
    GetMenuList = '/AutoAttendant/get_menu_list',
    GetMenuTransitionList = '/AutoAttendant/get_menu_transition_list',
    GetMenuTransitionPrompt = '/AutoAttendant/get_menu_transition_prompt',
    UpdateMenu = '/AutoAttendant/update_menu',
    SetMenuTransition = '/AutoAttendant/set_menu_transition',
    DelMenuTransition = '/AutoAttendant/del_menu_transition',
    SetMenuTransitionPrompt = '/AutoAttendant/set_menu_transition_prompt',
    SetMenuPrompt = '/AutoAttendant/set_menu_prompt',
    DelMenu = 'AutoAttendant/del_menu',
    CreateMenu = '/AutoAttendant/create_menu',
}

export enum CDR {
    GetCallRecording = '/CDR/get_call_recording',
    BatchDeleteCallRecording = '/CDR/batch_delete_call_recording',
    GetTranscription = '/CDR/get_transcription'
}
export enum BillingSession {
    GetActiveCallsList = '/BillingSession/get_active_calls_list',
    GetActiveSessionsList = '/BillingSession/get_active_sessions_list'
}

export enum Session {
    Logout = '/Session/logout'
}

export enum UA {
    AddUa = '/UA/add_ua',
    GetUaList = '/UA/get_ua_list',
    GetUaTypeList = '/UA/get_ua_type_list',
    DeleteUA = '/UA/delete_ua',
    UpdateUa = '/UA/update_ua',
    UploadUaList = '/UA/upload_ua_list'
}

export enum CodecConverter {
    GetFileList = '/CodecConverter/get_file_list',
    AddFile = '/CodecConverter/add_file',
    GetFile = '/CodecConverter/get_file',
    DeleteFile = '/CodecConverter/delete_file',
    EditFile = '/CodecConverter/update_file'
}

export enum DialingRule {
    GetiDialingRulesList = '/DialingRule/get_dialing_rules_list',
    GetDialingRuleInfo = '/DialingRule/get_dialing_rule_info',
    UpdateDialingRule = '/DialingRule/update_dialing_rule',
    GetFile = '/CodecConverter/get_file',
}

export enum Products {
    ValidateAddonsCombination = '/Product/validate_addons_combination',
}

export enum Payment {
    GetPaymentMethodsForOwner = '/Payment/get_payment_methods_for_owner',
    GetPaymentTransactionById = '/Payment/get_payment_transaction_by_id',
    FinalizeTransaction = '/Payment/finalize_transaction',
    RegisterTransaction = '/Payment/register_transaction',
}

export enum Invoice {
    'GetInvoiceList' = '/Invoice/get_invoice_list',
    'GetInvoiceInfo' = '/Invoice/get_invoice_info',
}

export enum Service {
    'GetServiceList' = '/Service/get_service_list',
}

export enum PortalView {
    GetPortalViewConfigurationInfo = '/PortalView/get_portal_view_configuration_info',
    GetPortalViewConfigurationList = '/PortalView/get_portal_view_configuration_list',
}

export enum CallControl {
    EnableApiNotifications = '/CallControl/enable_api_notifications',
    GetSipCallsList = '/CallControl/get_sip_calls_list'
}

export enum AccessControl {
    GenerateMfaConfig = '/AccessControl/generate_mfa_config',
    ConfirmMfaConfig = '/AccessControl/confirm_mfa_config',
    VerifyOtp = '/AccessControl/verify_otp',
    GetMfaConfig = '/AccessControl/get_mfa_config',
    SetMfaConfig = '/AccessControl/set_mfa',
    ResetMfaConfig = '/AccessControl/reset_mfa_config',
}