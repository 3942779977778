import classNames from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';
import {
    FetchPaginateAccounts,
    WidgetCallVolumesData,
    WidgetCallVolumesTimeRange,
    WidgetPresantationType
} from "../../../store/types/Wallboard";
import {useStyles} from './CallAllTransactionsVolumesWidget.utils';
import {MenuItem, Select} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../store';
import {ReduxState} from '../../../store/types';
import {DropDownDictionaryItem} from '../../CallHistory/ActiveCalls/ActiveCalls.utils';
import EmptyGridPlaceholder from '../../../components/DataGrid/EmptyGridPlaceholder';
import WidgetLoadingMask from '../WidgetLoadingMask';
import CustomizedCheckbox from "../../../components/Checkbox/Checkbox";
import LazyCallVolumesWidgetListViewItem from "./LazyCallVolumesWidgetListViewItem";

export type CallAllTransactionsVolumesWidget = {
    data: WidgetCallVolumesData,
    id: string,
    presentation: WidgetPresantationType,
    disableCustomRange: boolean,
    darkMode: boolean,
};

const CallAllTransactionsVolumesWidget: React.VFC<CallAllTransactionsVolumesWidget> = (
    {
        data,
        id,
        presentation,
        disableCustomRange,
        darkMode
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const allAccountsWithTransaction = useSelector((state: ReduxState) => state.wallboard.allAccountsWithTransaction);
    const find = allAccountsWithTransaction.find(el => el.widgetId == id)

    const initDataForWidget = find ? {...find, item: [...find.items]} : undefined

    const lastData = {
        isEmpty: true,
        loading: false,
        id: '-1',
        extension: {extension_id: '-1'},
        details: {
            sum: -1,
            duration: -1
        }
    }

    const [customRange, setCustomRange] = useState<WidgetCallVolumesTimeRange>(data.timeRange);
    const reqParams: FetchPaginateAccounts = useMemo(() => { return {
        id, 
        init: true, 
        extension: data.extensions,
        ringGroup: data.ringGroup,
        meta: {offset: 0, limit: 10}
    };}, [data, id]);
    const [isChecked, setIsChecked] = useState<boolean>(false)

    const fetchExtensions = () => {

        dispatch(actions.callAllTransactionDataForWidget.request(initDataForWidget ? {
                meta: {offset: initDataForWidget.meta.meta.offset, limit: initDataForWidget.meta.meta.limit},
                extension: data.extensions,
                ringGroup: data.ringGroup,
                init: initDataForWidget.init,
                id: initDataForWidget.widgetId
            } : reqParams));
    }

    function refresh() {
        dispatch(actions.callAllTransactionDataForWidget.request({
            id, init: true, extension: data.extensions,
            ringGroup: data.ringGroup, meta: {offset: 0, limit: 10}
        }));
        dispatch(actions.refreshWidgetCompleted.request(id));
    }

    useEffect(() => {
        fetchExtensions()
    }, [id, data]);

    const {refreshWidgetsQueue} = useSelector((state: ReduxState) => state.wallboard);
    const isLoadingChart = useMemo(() => !!(refreshWidgetsQueue?.find(e => e.id === id)),
        [refreshWidgetsQueue, id]);

    useEffect(() => {
        const itm = refreshWidgetsQueue?.find(e => e.id === id);
        if (itm && itm.dataHasLoaded) {
            refresh();
        }
    }, [refreshWidgetsQueue]);

    useEffect(() => {
        const _id = 'native-select-container' + id;
        const el = document.getElementById(_id);
        const subItems = el?.getElementsByClassName('MuiSelect-root');
        const name = widgetCallVolumesTimeRangeList
            .find(e => e.value === customRange)?.name;
        if (subItems && subItems?.length > 0) {
            subItems[0].innerHTML = t('screens:wallboard.callsInLast', {
                value: name
            });
        }
    }, [id, customRange, data, presentation]);

    const widgetCallVolumesTimeRangeList = useMemo(
        () => {
            const values = [
                WidgetCallVolumesTimeRange.min60,
                WidgetCallVolumesTimeRange.min180,
                WidgetCallVolumesTimeRange.min300,
                WidgetCallVolumesTimeRange.min420,
                WidgetCallVolumesTimeRange.min1440,
                WidgetCallVolumesTimeRange.min4320,
                WidgetCallVolumesTimeRange.min7200,
                WidgetCallVolumesTimeRange.min10080
            ];
            if (!values.find(e => e === data.timeRange)) {
                values.push(data.timeRange);
                values.sort((a, b) => (a as number) - (b as number));
            }
            return values
                .map((v) => ({
                    name: t(`enums:widgetCallVolumesTimeRange.${v}`),
                    value: v as WidgetCallVolumesTimeRange,
                } as DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>));
        },
        [data],
    );


    return (<>
            <div id={'call_volumes_widget_' + id}
                 className={classNames(classes.widgetMainContainer, presentation === WidgetPresantationType.list && classes.overflowStyleForListWidgetType)}>
                {presentation === WidgetPresantationType.list && (
                    <div className={classes.listViewMainContainer}>
                        <div className={classes.listViewHeaderContainer}>
                            {initDataForWidget && (<div
                                className={classNames(classes.listViewHeaderItem, darkMode && classes.listViewHeaderItemInDarkMode)}>
                                {t('screens:wallboard.extension')}
                            </div>)}
                            <div id={'native-select-container' + id} className={classes.nativeSelectContainer}>
                                <CustomizedCheckbox
                                    dataQa={'hide-extension-with-no-calls-checkbox' + id}
                                    label={t('screens:wallboard.hideExtensionsWithNocalls')}
                                    value={isChecked}
                                    checked={isChecked}
                                    onChange={() => setIsChecked(prevState => !prevState)}
                                    className={classNames(classes.visible, classes.nativeSelect, classes.nativeSelectInList, darkMode && classes.nativeSelectDarkMode)}
                                />
                                <Select
                                    id={"customTimeRange_list_" + id}
                                    value={customRange as number}
                                    onChange={(e) => {
                                        setCustomRange(e.target.value as WidgetCallVolumesTimeRange);
                                    }}
                                    disableUnderline
                                    disabled={disableCustomRange}
                                    variant='standard'
                                    className={classNames(classes.nativeSelect, classes.nativeSelectInList, darkMode && classes.nativeSelectDarkMode)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {
                                        widgetCallVolumesTimeRangeList.map((r, i) => (
                                            <MenuItem
                                                value={r.value as number}
                                                key={'option_key_' + i}
                                                className={classes.nativeSelectOption}
                                            >
                                                {r.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className={classes.listViewItemsContainer}>
                            {
                                initDataForWidget && initDataForWidget?.items &&
                                initDataForWidget?.items.map((e, i) => (
                                    <LazyCallVolumesWidgetListViewItem
                                        // @ts-ignore
                                        extension={e.extension}
                                        key={'list_view_extension_' + e.id + '_' + i}
                                        numberOfCalls={data.numberOfCalls}
                                        darkMode={darkMode}
                                        period={customRange}
                                        id={id + '-' + i}
                                        hideWhenNoCalls={isChecked}
                                    />
                                ))
                            }

                            {initDataForWidget?.hasMore && (
                                <LazyCallVolumesWidgetListViewItem
                                    // @ts-ignore
                                    extension={lastData.extension}
                                    key={'list_view_extension_' + lastData.id + '_' + initDataForWidget?.items.length}
                                    numberOfCalls={data.numberOfCalls}
                                    darkMode={darkMode}
                                    period={customRange}
                                    id={id + '-' + initDataForWidget.items.length}
                                    isLast={true}
                                    fetchMore={fetchExtensions}
                                    hideWhenNoCalls={isChecked}
                                />
                            )}
                            {
                                <EmptyGridPlaceholder
                                    showNoDataImg
                                    message={t('common:noData')}
                                    isVisible={!initDataForWidget}
                                    customClass={classes.noDataContainer}
                                />
                            }
                        </div>
                    </div>
                )}
            </div>
            <WidgetLoadingMask
                isLoadingChart={isLoadingChart} darkMode={darkMode}
                customClasses={{
                    container: classNames(presentation === WidgetPresantationType.doughnut && classes.loadingBackgroundDoughnut,
                        presentation === WidgetPresantationType.doughnut && classes.loadingBackgroundList),
                    label: classNames(presentation === WidgetPresantationType.doughnut && classes.loadingLabelDoughnut,
                        presentation === WidgetPresantationType.doughnut && classes.loadingLabelList),
                }}
            />
        </>
    );
};

export default CallAllTransactionsVolumesWidget;