import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {actions} from '../../../store';
import {useTranslation} from 'react-i18next';
import {PaginationMode} from '../../../components/DataGrid/types';
import DataGrid from '../../../components/DataGrid/DataGrid';
import {
    ActiveCallsFiltersProps,
    DropDownDictionaryItem,
    generateColumns,
    initialValues,
    prepareData, 
    useStyles
} from './ActiveCalls.utils';
import ActiveCallsInfo from '../../../components/Calls/ActiveCallsInfo';
import {SipCall, SipCallGroup, SipCallState, SipCallType} from '../../../store/actions/ringgroups/payloads';
import {ENABLE_NOTIFICATIONS_ACCESS_DENIED, GET_SIP_CALLS_ACCESS_DENIED} from '../../../store/sagas/ringgroups/saga';
import AccessDenied from '../../../components/PermissionProvider/AccessDenied';
import {Grid} from '@material-ui/core';
import {useFormik} from 'formik';
import Button from '../../../components/Button/Button';
import {usePermissions} from "../../../hooks/usePermissions";
import {Permission} from "../../../store/types/Permission";
import ExtensionAsyncSelectFiled from "../../../components/CallControls/ExtensionAsyncSelectFiled";
import {SearchCallback} from "../CallHistory.utils";
import AsyncSelectField from '../../../components/AsyncSelectField/AsyncSelectField';
import WebSocketDataProvider from '../../../components/WebSocketDataProvider/WebSocketDataProvider';
import Config from '../../../config.json';

export const ActiveCalls: React.VFC<SearchCallback> = ({callback}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const actionPermission = usePermissions(...Permission.Calls.Activity.CurrentCalls.ControlCalls.value);

    const {ringGroupsList, isLoadingSipCalls, sipCallsList, sipCallsApiError, accounts,isLoadingRingGroupsAccounts} = useSelector(
        (state: ReduxState) => state.ringgroups,
    );

    const [filtered, setFiltered] = useState<SipCall[] | undefined>();

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const filterItems = useCallback((filters: ActiveCallsFiltersProps) => {
        if (!filters) return sipCallsList;
        let ringGroupFilter: string | undefined = undefined;
        if(filters?.ringGroup) {
            ringGroupFilter = ringGroupsList?.items?.find(e => (e.i_c_group + '') === (filters.ringGroup + ''))?.id;
        }
        return sipCallsList
                ?.filter(e => !filters.state || e.state === filters.state)
                ?.filter(e => !filters.type || e.type === filters.type)
                ?.filter(e => !filters.extension?.extension_id || (
                    (e.callee?.extension_id && e.callee.extension_id === filters.extension.extension_id) || 
                    (e.caller?.extension_id && e.caller.extension_id === filters.extension.extension_id)
                ))
                ?.filter(e => !ringGroupFilter || (
                    (e.callee?.huntgroup_id && e.callee.huntgroup_id === ringGroupFilter) || 
                    (e.caller?.huntgroup_id && e.caller.huntgroup_id === ringGroupFilter)
                ))
            || [];
    }, [sipCallsList, ringGroupsList]);

    useEffect(() => {
        dispatch(actions.ringGroupsList.request({
        }));
        dispatch(actions.getSipCallsList.request({}));
        dispatch(actions.getRingGroupsAccountList.request());
    }, []);

    const {
        values,
        handleSubmit,
        setFieldValue,
    } = useFormik<ActiveCallsFiltersProps>({
        initialValues,
        onSubmit: () => {
            dispatch(actions.getSipCallsList.request({}));
            dispatch(actions.getRingGroupsAccountList.request());
        },
        enableReinitialize: true,
        validateOnChange: false,
    });

    const columns = useMemo(() => generateColumns(
        accounts,
        ringGroupsList?.items,
        t,
        classes,
        timezoneOffset,
        actionPermission,
        userDateTimeFormat,
        handleSubmit,
        isLoadingRingGroupsAccounts,
        sipCallsList,
    ), [accounts, ringGroupsList,isLoadingRingGroupsAccounts]);

    const stateSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<SipCallState | undefined>[] = [{
                name: t('common:any'),
                value: undefined
            }];
            const arr = Object.values(SipCallState)
                .filter(e => e === SipCallState.Trying ||
                    e === SipCallState.Ringing ||
                    e === SipCallState.Connected ||
                    e === SipCallState.Holding ||
                    e === SipCallState.Queued
                )
                .map((v) => ({
                    name: t(`enums:sipCallState.${v}`),
                    value: v,
                } as DropDownDictionaryItem<SipCallState>));
            arr.map(e => init.push(e));
            return init;
        }, []
    );

    const typeSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<SipCallType | undefined>[] = [{
                name: t('common:any'),
                value: undefined
            }];

            const arr = Object.values(SipCallType).map((v) => ({
                name: t(`enums:sipCallType.${v}`),
                value: v,
            } as DropDownDictionaryItem<SipCallType | undefined>));

            arr.map(e => init.push(e));

            return init;
        }, [],
    );

    const ringGroupsSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<string>[] = [{
                name: t('common:any'),
                value: undefined
            }];
            ringGroupsList?.items?.map(e => {
                init.push({
                    name: e.name,
                    value: e.i_c_group.toString()
                })
            });
            return init;
        }, [ringGroupsList],
    );

    const loadOptionsState = useCallback(async () => {
        return {
            options: stateSelectList,
            hasMore: false,
        };
    }, [stateSelectList]);
    
    const loadOptionsType = useCallback(async () => {
        return {
            options: typeSelectList,
            hasMore: false,
        };
    }, [typeSelectList]);

    const loadOptionsRingGroups = useCallback(async () => {
        return {
            options: ringGroupsSelectList,
            hasMore: false,
        };
    }, [ringGroupsSelectList]);

    useMemo(() => {
        setFiltered(filterItems(values));
    }, [sipCallsList, values]);

    const callStatusUpdateCallback = useCallback((call: SipCall) => {
        if(!call) {
            return;
        }
        dispatch(actions.updateSipCallStatusByWebSocketEvent.request(call));
    }, []);

    const recalcCallsDurationEvent = useCallback(() => {
        dispatch(actions.recalcSipCallsDuration.request());
    }, []);

    if (sipCallsApiError?.faultcode === ENABLE_NOTIFICATIONS_ACCESS_DENIED
        || sipCallsApiError?.faultcode === GET_SIP_CALLS_ACCESS_DENIED) {
        return <AccessDenied subPageMode/>;
    }
    
    const hasWebSocket = useMemo(() => {
        if(!Config.WEBSOCKET_SERVER_URL?.length) {
            return false;
        }
        return true;
    }, [Config]);

    return (
        <>
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="call-history-form"
                className={classes.formsContainer}
            >
                <Grid item className={classes.filtersContainer}>
                    <Grid item className={classes.itemsContainer}>
                        <Grid item className={classes.inputsContainer}>
                            <AsyncSelectField
                                title={t('screens:calls.callState')}
                                loadOptions={loadOptionsState}
                                value={stateSelectList.find((v) => v.value === values.state)}
                                onChange={(option: DropDownDictionaryItem<SipCallState>) => {
                                    setFieldValue('state', option.value);
                                }}
                                dataQa={'current-calls-select-field-state'}
                                disableClearable
                                formatOptionLabel={(option: DropDownDictionaryItem<SipCallState>) => {
                                    return <span>{option.name}</span>;
                                }}
                                debounceTimeout={0}
                                className={hasWebSocket ? classes.extensionField : classes.extensionFieldWithoutSocket}
                            />
                            <AsyncSelectField
                                title={t('screens:calls.callDirection')}
                                loadOptions={loadOptionsType}
                                value={typeSelectList.find((v) => v.value === values.type)}
                                onChange={(option: DropDownDictionaryItem<SipCallType>) => {
                                    setFieldValue('type', option.value);
                                }}
                                dataQa={'current-calls-select-field-type'}
                                disableClearable
                                formatOptionLabel={(option: DropDownDictionaryItem<SipCallType>) => {
                                    return <span>{option.name}</span>;
                                }}
                                debounceTimeout={0}
                                className={hasWebSocket ? classes.extensionField : classes.extensionFieldWithoutSocket}
                            />
                            <ExtensionAsyncSelectFiled
                                //@ts-ignore
                                value={values.extension}
                                setValue={(value) => {
                                    setFieldValue('extension', value)
                                    setFieldValue('ringGroup', undefined);
                                }}
                                customClass={hasWebSocket ? classes.extensionField : classes.extensionFieldWithoutSocket}
                                required={false}
                                useAny={true}
                                showRegisterStatus={false}
                                disableClearable={true}
                                forceEnable={true}
                                useExtensionFilter={true}
                            />
                            <AsyncSelectField
                                title={t('screens:calls.ringGroup')}
                                loadOptions={loadOptionsRingGroups}
                                value={ringGroupsSelectList.find((v) => v.value === values.ringGroup)}
                                onChange={(option: DropDownDictionaryItem<string>) => {
                                    setFieldValue('ringGroup', option.value);
                                    setFieldValue('extension', undefined);
                                }}
                                dataQa={'current-calls-select-field-ring-group'}
                                disableClearable
                                formatOptionLabel={(option: DropDownDictionaryItem<string>) => {
                                    return <span>{option.name}</span>;
                                }}
                                debounceTimeout={0}
                                className={hasWebSocket ? classes.extensionField : classes.extensionFieldWithoutSocket}
                            />
                        </Grid>
                        <Button
                            primary
                            accent
                            dataQa="current-calls-search"
                            className={classes.button}
                            onClick={() => {handleSubmit(); callback?.()}}
                            skipPermission
                        >
                            {t('common:search')}
                        </Button>

                        {!hasWebSocket && (<ActiveCallsInfo/>)}
                    </Grid>
                </Grid>
            </form>
            <DataGrid<SipCall | SipCallGroup>
                // @ts-ignore
                columns={columns}
                data={prepareData(filtered?.map((v, index) => ({
                    ...v,
                    id: index.toString()
                })) || [], ringGroupsList.items) || []}

                rowCount={filtered?.length || 0}
                loading={isLoadingSipCalls}
                paginationMode={PaginationMode.Client}
                centeredRows
                narrowRows
                customRowHeight={63}
                customRowHeight2={30}
                initialPageSize={10}
                classes={{
                    tableContainer: classes.tableContainer,
                    header: classes.tableContainer,
                }}
            />
            <WebSocketDataProvider callStatusUpdate={callStatusUpdateCallback} recalcCallsDurationEvent={recalcCallsDurationEvent} >
            </WebSocketDataProvider>
        </>
    );
};
