import {InvoiceStatus} from '../../views/Billing/Invoices/InvoiceFilters.utils';
import {WidgetCallVolumesTimeRange} from "./Wallboard";
import {APIErrorInterface} from "./errors";
import {ExtensionType} from "./Extension";

export interface InvoiceList {
    items: InvoiceListEntity[];
    summary: any;
    total: number;
}

export interface GetInvoiceListPayload {
    from_date?: string;
    to_date?: string;
    issued_before?: string;
    covers_date?: string;
    issued_after?: string;
    offset?: number;
    limit?: number;
    hide_void?: number;
    status?: InvoiceStatus;
    invoiceNumber?: string;
}

export interface GetInvoiceFilePayload {
    get_pdf: string;
    invoice_number: number;
    fileName: string;
    callback?: () => void;
}

export interface GetInvoiceListResponse {
    invoice_list: InvoiceListEntity[];
    total: number;
    invoices_summary: any;
}

export interface InvoiceListEntity {
    payments: number;
    tax_method: number;
    iso_4217: string;
    previous_balance: number;
    subscriptions: number;
    i_invoice: number;
    additional_invoices: number;
    i_invoice_type: number;
    prior_balance_paid: number;
    is_pdf_exists: boolean;
    due_date: string;
    i_invoice_status: number;
    is_void: string;
    customer_name: string;
    taxes: number;
    invoice_status: string;
    pdf_created: string;
    amount_paid: number;
    manual_charges: number;
    amount_due: number;
    prior_balance: number;
    closed: string;
    last_notific_date: string;
    calls: number;
    adjustments: number;
    period_to: string;
    amount_net: number;
    new_style: number;
    invoice_number: number;
    period_from: string;
    issue_date: string;
    i_customer: number;
}

export interface TransactionList {
    items: ServiceInfo[];
    iso_4217: string;
    total_amount: number;
    count: number;
}

export interface MultipleServiceInfo {
    id: string,
    items: ServiceInfo[]
}

export interface CustomWidgetPeriods {
    id:string,
    period: WidgetCallVolumesTimeRange
}

export interface ServiceInfo {
    charged_amount: number;
    charged_quantity: number;
    charges: number;
    credits: number;
    i_service: number;
    i_service_type: number;
    name: string;
    rate_unit: string;
    rating_base: string;
    rating_base_unit: string;
    ratio: number;
    total: number;
    total_sessions: number;
    unit: string;
    color?: string
}

export interface TransactionGetTotalInfo {
    name: string;
    value: number;
}

export interface GetTransactionsTotalInfoResponse {
    count: number;
    iso_4217: string;
    per_service_info: ServiceInfo[];
    sessions_count: number;
    total_amount: number;
    total_info: TransactionGetTotalInfo[];
}

export interface GetTransactionsListPayload {
    billing_model?: number;
    cld?: string;
    cli?: string;
    from_date?: string;
    i_customer?: number;
    i_service?: number;
    show_unsuccessful?: number;
    to_date?: string;
}

export enum PaymentMethodType {
    CreditCard,
    BankAccount,
    DirectDebitNL,
    PayPal,
    PayNearMe,
    Luottokunta,
    PayArena,
    Remita,
    MoneyBookers,
    VCS,
    WorldPay
}


export interface CustomWidgetPeriods {
    id: string,
    period: WidgetCallVolumesTimeRange
}

export interface IAccountCustomWidgetPeriods {
    i_account: number,
    id: string,
    period: WidgetCallVolumesTimeRange
}

export interface WidgetItemCalls {
    id: string,
    isEmpty?:boolean,
    extension?:ExtensionType
    loading:boolean,
    loaded:boolean,
    period: WidgetCallVolumesTimeRange,
    details : {
        sum: number,
        duration: number
    }
}

export interface IAccountCustomWidgetPeriodsFail {
    id: string,
    error: APIErrorInterface
}